jQuery('.variations select').change( function() {

	var selects_names = [];
	jQuery('.variations select').each( function() {
		var value = this.value;
		value = value.replace('.', "_");
		var name = jQuery(this).attr("name");
		if(value) {
			var selection = name + '_' + value;
			selects_names.push( selection  );
		} else {
			selects_names.push( 'waiting' );
		}
	});
	var selected_class = selects_names.join(".");

	if(jQuery.inArray( 'waiting' , selects_names) !== -1) {
		jQuery('.variation_prices .variable_price').removeClass('active');
	} else {
		jQuery('.variation_prices .variable_price').removeClass('active');
		jQuery('.variation_prices .'+ selected_class).addClass('active');
	}
	jQuery('.variation_prices .variable_price.'+ selected_class).addClass('active');

	console.log('.variation_prices .variable_price.'+ selected_class);

});


jQuery(".woocommerce .products").on("click", ".quantity input", function() {
	return false;
});
jQuery(".woocommerce .products").on("change input", ".quantity .qty", function() {
	var add_to_cart_button = jQuery(this).parents( ".product" ).find(".add_to_cart_button");
	add_to_cart_button.attr("data-quantity", jQuery(this).val());
	add_to_cart_button.attr("href", "?add-to-cart=" + add_to_cart_button.attr("data-product_id") + "&quantity=" + jQuery(this).val());
});

jQuery(".woocommerce .products").on("keypress", ".quantity .qty", function(e) {
	if ((e.which||e.keyCode) === 13) {
		jQuery( this ).parents(".product").find(".add_to_cart_button").trigger("click");
	}
});
