jQuery('.product_carousel').slick({
	infinite: true,
	slidesToShow: 5,
	slidesToScroll: 1,
	dots: false,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 4500,
	pauseOnHover: false,
	pauseOnFocus: false,
	centerMode: true,
	centerPadding: '15px',
	responsive: [
		{
			breakpoint: 1240,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 800,
			settings: {
				slidesToShow: 2,
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			}
		},
	],
});

const slickNav = document.querySelectorAll('.carousel-nav')
if(slickNav) {
	slickNav.forEach( (nav) => {
		nav.addEventListener("click", () => {
			let slider = nav.dataset.slick,
				dir = nav.dataset.dir
			
			let slickDir = (dir === 'prev') ? 'slickPrev' : 'slickNext'

			jQuery('.' + slider).slick(slickDir)
		})
	})
}